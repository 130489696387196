import getConfig from "next/config";
import { CANDIDATE } from "../../Constants";
const { publicRuntimeConfig } = getConfig();

const workExperienceConfig = {
  key: "workExperience",
  label: (jobProps, userData) => {
    const userHasWorkExperience = userData.candidate.careerEntries.find(
      (careerEntry) => careerEntry.activity.considerAsWorkExperience
    );

    if (!userHasWorkExperience) {
      return "Für Berufseinsteiger";
    } else {
      return "Berufserfahrung";
    }
  },
  skip: (jobProps, userData, attributes) => {
    const fullyQualifiedLawyerWithWorkExperienceId =
      publicRuntimeConfig.degree
        .fully_qualified_lawyer_with_work_workexperience;

    const userHasWorkExperience = userData.candidate.careerEntries.find(
      (careerEntry) => careerEntry.activity.considerAsWorkExperience
    );

    return (
      !jobProps.jobDegreeIds ||
      (Array.isArray(jobProps.jobDegreeIds) &&
        jobProps.jobDegreeIds.length === 0) ||
      (userHasWorkExperience &&
        !jobProps.jobDegreeIds.includes(
          fullyQualifiedLawyerWithWorkExperienceId
        )) ||
      userData.candidate.degree !== CANDIDATE.DEGREE.VOLLJURIST
    );
  },
  /**
   * check for existence of activities which should be considered as work experience
   * @param userData
   * @param attributes
   * @param jobProps
   * @returns {{uid: number, organisationalForm: {uid: number, name: string}, activity: {uid: number, name: string}, employerName: string, employer: null, periodStart: {formatted: {date: string, time: string}, timezone: {name: string, location: boolean}, timestamp: number}, geodata: {uid: number, gemeinde: string, latitude: number, keywordmapping: string, longitude: number}, periodEnd: {formatted: {date: string, time: string}, timezone: {name: string, location: boolean}, timestamp: number}}}
   */
  processMatching: (userData, attributes, jobProps) => {
    const fullyQualifiedLawyerWithoutWorkExperienceId =
      publicRuntimeConfig.degree
        .fully_qualified_lawyer_without_work_workexperience;

    if (
      jobProps.jobDegreeIds.includes(
        fullyQualifiedLawyerWithoutWorkExperienceId
      )
    ) {
      return true;
    }
    const userHasWorkExperience = userData.candidate.careerEntries.find(
      (careerEntry) => careerEntry.activity.considerAsWorkExperience
    );
    return userHasWorkExperience;
  },
};

export default workExperienceConfig;
