import { getDescriptionSorting } from "./JobLib";
import GeneralUtility from "../../../libs/GeneralUtility";
import styles from "../components/jobDetail/JobDescription/JobDescription.module.scss";
import JobDescriptionItem from "../components/jobDetail/JobDescriptionItem";

export const citiesNameLimiter = (cities, limit) => {
  let reducedCities = cities;
  let moreJobsLabel = "";
  if (cities.length > limit - 1) {
    reducedCities = cities.slice(0, limit - 1);

    const hiddenCities = cities.length - reducedCities.length;
    moreJobsLabel = ` und ${hiddenCities}`;

    if (hiddenCities > 1) {
      moreJobsLabel += "  weitere";
    } else {
      moreJobsLabel += "  weiterer";
    }
  }
  return [reducedCities, moreJobsLabel];
};

export const escapeRegExp = (string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
};

export const getFacetWithValue = (
  facets,
  value,
  ignoreKeywords = false,
  onlyCheckFacetFilter = []
) => {
  value = value.toLowerCase();
  if (value) {
    for (const facetKey in facets) {
      const facet = facets[facetKey];
      const facetParam = facet.param;
      if (
        onlyCheckFacetFilter.length > 0 &&
        !onlyCheckFacetFilter.includes(facetParam)
      ) {
        continue;
      }

      if (facet.items) {
        for (const facetItem of facet.items) {
          if (!facetItem.title) continue;

          const keywordRegex = new RegExp("\\b" + escapeRegExp(value) + "\\b");
          if (facetItem.title.toLowerCase().replace(" ", "-") === value) {
            // check if title matches query words (words concatenated by "-")
            return [facetParam, facetItem, true];
          } else if (
            !ignoreKeywords &&
            facetItem.search_input_mapping &&
            keywordRegex.test(facetItem.search_input_mapping.toLowerCase())
          ) {
            // check if keywords of facet item matches query words
            return [facetParam, facetItem, false];
          }
        }
      }
    }
  }
  return [null, null];
};

export const getSortedJobDescriptions = (job) => {
  const defaultJobDescriptionsStart = [
    "descriptionIntroduction",
    "description",
  ];
  const defaultJobDescriptionsEnd = ["descriptionFinalNote"];

  // convert name to camel case
  const descriptionSorting = getDescriptionSorting(job)
    .split(",")
    .map((descriptionType) =>
      GeneralUtility.convertHyphensToCamelCase(descriptionType)
    );
  const descriptions = defaultJobDescriptionsStart.concat(
    descriptionSorting,
    defaultJobDescriptionsEnd
  );

  return descriptions.map((descriptionType) => {
    if (descriptionType in job && job[descriptionType]) {
      return {
        header: job[descriptionType + "Header"],
        headerColor: job[descriptionType + "HeaderColor"],
        description: job[descriptionType],
      };
    }
    return {};
  });
};

export const isToday = (date) => {
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};
