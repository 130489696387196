import React, { useEffect, useState } from "react";
import {
  addBookmark,
  deleteBookmarks,
  fetchBookmarksList,
} from "../../services/FrontendBookmarksApiService";
import { showNotification } from "../../../../libs/NotificationUtility";
import { MESSAGE_TYPES } from "../../../../libs/Types";
import useSWR, { mutate } from "swr";
import Icon from "../../../../components/Icon";
import getConfig from "next/config";
import ConditionalWrapper from "../../../../components/ConditionalWrapper";
import { getFeatureNotAllowedTooltip } from "../../../user/lib/UserProfileFeatureUtility";
import Tooltip from "../../../../components/Tooltip";
import { useRouter } from "next/router";
import { TRIGGER_TYPE } from "baseui/tooltip";
import useWindowSize from "../../../../hooks/useWindowSize";
import Button from "../../../../components/Button";

const AddToBookmarks = ({
  id,
  type,
  variant = "default",
  layout = "button",
  typeButton = "primary",
  isLoggedInUser,
  className,
  initialBookmarks = [],
}) => {
  const { publicRuntimeConfig } = getConfig();
  const router = useRouter();
  const [pending, setPending] = useState();
  const [alreadyAddedToBookmarks, setAlreadyAddedToBookmarks] = useState(false);
  const { isMobile } = useWindowSize();

  const fetcher = () => {
    if (isLoggedInUser) {
      return fetchBookmarksList()
        .then((res) => res.json())
        .then((resp) => resp.bookmarks);
    } else {
      return initialBookmarks;
    }
  };

  const { data: bookmarks, mutate } = useSWR(
    () => (isLoggedInUser ? "bookmarks" : null),
    fetcher,
    {
      ...publicRuntimeConfig.globalSwrConfig,
      revalidateOnMount: true,
      revalidateOnFocus: true,
      initialData: initialBookmarks,
    }
  );

  useEffect(() => {
    if (isLoggedInUser) {
      const bookmarkedJobUids = Object.values(bookmarks).map(
        (bookmark) => `${bookmark.type}_${bookmark.uidForeign}`
      );

      setAlreadyAddedToBookmarks(bookmarkedJobUids.includes(`${type}_${id}`));
    }
  }, [isLoggedInUser, bookmarks]);

  const tooltipText = isLoggedInUser
    ? null
    : getFeatureNotAllowedTooltip(
        isLoggedInUser,
        publicRuntimeConfig.features["default"].slogan,
        publicRuntimeConfig.features["default"].loginSlogan,
        publicRuntimeConfig.features["default"].featureLevel
      );

  const handleAddToBookmarks = (e) => {
    e.preventDefault();
    if (pending || !isLoggedInUser) {
      return;
    }
    setPending(true);
    addBookmark(id, type)
      .then(async (resp) => {
        if (resp.ok) {
          setAlreadyAddedToBookmarks(true);
          showNotification(
            "Job wurde der Merkliste hinzugefügt.",
            MESSAGE_TYPES.SUCCESS,
            {
              actionOnClick: () => {
                router.push(publicRuntimeConfig.pagePaths.jobBookmarkList);
              },
              actionMessage: "Zur Merkliste",
            }
          );
          mutate();
        } else {
          const respData = await resp.json();
          console.log("FEHLER:", respData);
          showNotification(respData.errors?.[0], MESSAGE_TYPES.ALERT);
        }
      })
      .finally(() => {
        setPending(false);
      });
  };

  const handleBookmarkDelete = (e) => {
    e.preventDefault();

    if (pending || !isLoggedInUser) {
      return;
    }

    const bookmarkToDelete = Object.values(bookmarks).find(
      (bookmark) => bookmark.uidForeign === id
    );

    if (bookmarkToDelete) {
      setPending(true);
      deleteBookmarks([bookmarkToDelete.uid])
        .then(async (resp) => {
          if (resp.ok) {
            setAlreadyAddedToBookmarks(false);
            mutate();
            showNotification(
              "Der Bookmark wurde gelöscht.",
              MESSAGE_TYPES.SUCCESS
            );
          } else {
            const respData = await resp.json();
            console.log("FEHLER:", respData);
            showNotification(respData.errors?.[0], MESSAGE_TYPES.ALERT);
          }
        })
        .finally(() => {
          setPending(false);
        });
    }
  };

  const renderAsButton = (text, handleAction, icon) => (
    <Button
      layout={typeButton}
      startEnhancer={() => <Icon name={icon} size="medium" />}
      onLinkClickCustom={handleAction}
    >
      {text}
    </Button>
  );

  const renderAsIcon = (text, handleAction, icon) => (
    <button
      className="add_to_bookmarks__button"
      onClick={handleAction}
      title={text}
    >
      <Icon name={icon} size="base" otherClass="mg-global-0 color-black" />
    </button>
  );

  const renderItem = (alreadyAdded) => {
    const text = alreadyAdded ? "Job nicht merken" : "Job merken";
    const icon = alreadyAdded ? "favorite-fill" : "favorite";
    const handleAction = alreadyAdded
      ? handleBookmarkDelete
      : handleAddToBookmarks;

    if (isLoggedInUser) {
      return renderAsIcon(text, handleAction, icon);
    } else if (layout === "button") {
      return renderAsButton(text, handleAction, icon);
    } else {
      return renderAsIcon(text, handleAction, icon);
    }
  };

  return (
    <ConditionalWrapper
      condition={tooltipText}
      wrapper={(children) => (
        <Tooltip
          icon=""
          text={tooltipText}
          className={className}
          triggerType={isMobile ? TRIGGER_TYPE.click : TRIGGER_TYPE.hover}
        >
          {children}
        </Tooltip>
      )}
    >
      <div
        className={`add_to_bookmarks ${className}`}
        data-testid="add-to-bookmarks-button"
      >
        {renderItem(alreadyAddedToBookmarks)}
      </div>
    </ConditionalWrapper>
  );
};

export default AddToBookmarks;
