import React, { useEffect, useState } from "react";
import Link from "next/link";
import { InView } from "react-intersection-observer";
import { LazyLoadImage } from "react-lazy-load-image-component";
import styles from "../JobList/JobList.module.scss";
import JobSubheader from "../JobSubheader";
import PagePathUtility from "../../../../../libs/PagePathUtility";
import JobFromSolrLib from "../../../libs/JobFromSolrLib";
import AddToBookmarks from "../../../../bookmarks/components/AddToBookmarks";
import ProfilMatching from "../../../../user/components/global/ProfilMatching";
import useSWR from "swr";
import { hasRole } from "../../../../user/lib/UserProfileFeatureUtility";
import {
  explainMatching,
  getMatching,
  processMatching,
} from "../../../../user/lib/MatchingScore/MatchingUtility";
import ContentApiService from "../../../../cms_content/services/ContentApiService";
import getConfig from "next/config";
import UserApiService from "../../../../user/services/UserApiService";
import useUserData from "../../../../user/hooks/useUserData";
import { isToday } from "../../../libs/GeneralLib";

const JobListItem = ({
  job,
  searchArgs,
  searchMeta,
  searchBoosting,
  attributes,
}) => {
  const { publicRuntimeConfig } = getConfig();
  const { userData, isValidating, error } = useUserData();
  const [hidden, updateState] = useState(false);
  const hasRoleDefault = hasRole("default", userData?.user);
  const matchingFeatureUnlocked = hasRole("matching", userData?.user);

  const waitBeforeShow = (e) => {
    if (!hidden) {
      updateState(e);
    }
  };

  const jobDetailLink = PagePathUtility.getPathConfig("jobDetailFromSolr", {
    ...job,
  });

  const {
    matchPercent,
    matchCount,
    matchFromCount,
    matchTooltipText,
    hideMatchingScore,
  } = getMatching(JobFromSolrLib.getUid(job), userData, attributes, {
    jobIsBundesweit: JobFromSolrLib.getGeoDatas(job).some(
      (jobLocation) =>
        jobLocation === publicRuntimeConfig.specialJobLocations.bundesweit
    ),
    jobIsRemoteOnly: JobFromSolrLib.getGeoDatas(job).some(
      (jobLocation) =>
        jobLocation === publicRuntimeConfig.specialJobLocations.remoteOnly
    ),
    jobCoordinatesLatLon: JobFromSolrLib.getCoordinates(job),
    jobOrganisationalFormId: JobFromSolrLib.getOrganisationalForm(job),
    jobActivityIds: JobFromSolrLib.getActivities(job),
    jobDegreeIds: JobFromSolrLib.getCareerLevels(job),
    jobFieldOfLawIds: JobFromSolrLib.getFieldOfLaws(job),
    jobEmploymentTypeIds: JobFromSolrLib.getEmploymentTypes(job),
    jobSalaryConfig: JobFromSolrLib.getSalaryConfig(job),
    jobScoreMinPoints: JobFromSolrLib.getScoreMinPoints(job),
    jobScoreMinGrade: JobFromSolrLib.getScoreMinGrade(job),
  });

  // if (isValidating && !error) {
  //   return <div>Loading...</div>;
  // }
  return (
    <InView
      threshold={0.3}
      triggerOnce="true"
      onChange={waitBeforeShow}
      as="li"
      key={JobFromSolrLib.getUid(job)}
      className={`${
        hidden ? styles.jobitem__show : styles.jobitem__hidden
      } list-item--job`}
      data-testid={isValidating && !error && "userdataLoading"}
      data-id={JobFromSolrLib.getUid(job)}
      data-score={job.score}
    >
      <Link href={jobDetailLink} prefetch={false}>
        <a className={styles.jobitem__content}>
          {/* Begin: Job Item logo */}
          <span className={styles.jobitem__logo}>
            {JobFromSolrLib.getCompanyLogo(job) > 0 && (
              <LazyLoadImage
                id={`job${JobFromSolrLib.getUid(job)}`}
                src={ContentApiService.getImageUrl(
                  JobFromSolrLib.getCompanyLogo(job)
                )}
                alt={JobFromSolrLib.getCompanyName(job)}
                effect="opacity"
                wrapperClassName={styles.jobitem__lazylogo}
              />
            )}
          </span>
          {/* End: Job Item logo */}

          {/* Begin: Job Item info text */}
          <div className={styles.jobitem__info}>
            <p className={styles.jobitem__title}>
              {JobFromSolrLib.getTitle(job)}
            </p>
            <JobSubheader
              employerName={JobFromSolrLib.getEmployerName(job)}
              locations={JobFromSolrLib.getLocations(
                job.geodatas_intM,
                job.truecities_stringM
              )}
              searchArgs={searchArgs}
              searchMeta={searchMeta}
              searchBoosting={searchBoosting}
            />

            {job.endtime_intS > 0 &&
              isToday(new Date(job.endtime_intS * 1000)) && (
                <div className={styles.job_expires}>Endet heute</div>
              )}

            {/* Begin: Job Item details list */}
            {/*<JobsListDetails */}
            {/*    large={false}*/}
            {/*    size="small"*/}
            {/*/>*/}
            {/* End: Job Item details list */}
          </div>
          {/* End: Job Item info text */}

          {/* Begin: Job Item profil matching */}
          <div className={`list-flex list-flex--center align-spaced`}>
            {attributes.employmentTypes && attributes.degrees && (
              <ProfilMatching
                percent={matchPercent}
                tooltipText={matchTooltipText}
                circleTextOverride={`${matchCount} / ${matchFromCount}`}
                circleTextUnit="passend"
                matchingFeatureUnlocked={matchingFeatureUnlocked}
                noMatchingPossible={hideMatchingScore}
                isLoggedInUser={hasRole("default", userData?.user)}
                typeButton="primary"
              />
            )}

            <AddToBookmarks
              id={JobFromSolrLib.getUid(job)}
              type="job"
              isLoggedInUser={hasRoleDefault}
              variant="small"
              layout="button"
              typeButton="secondary"
            />
          </div>

          {/* End: Job Item profil matching */}
        </a>
      </Link>
    </InView>
  );
};

export default JobListItem;
