import { CANDIDATE } from "../../Constants";
import GeneralUtility from "../../../../../libs/GeneralUtility";

const getDegreeSalaryConfig = (userDegree, salaryConfigs) => {
  const config = salaryConfigs.find((config) =>
    config.degree.includes(userDegree)
  );
  if (!config) {
    return null;
  }
  return { ...config };
};

const salaryConfig = {
  key: "salary",
  label: "Gehaltsvorstellung",
  skip: (jobProps, userData) =>
    !jobProps.jobSalaryConfig ||
    !Array.isArray(jobProps.jobSalaryConfig) ||
    jobProps.jobSalaryConfig.length === 0,
  processMatching: (userData, attributes, jobProps) => {
    let jobSalaryConfig = jobProps.jobSalaryConfig;
    if (
      jobSalaryConfig &&
      Array.isArray(userData?.candidate?.activeSalaryTypes) &&
      userData.candidate?.activeSalaryTypes.length > 0
    ) {
      let userHasSalaryDefined = false;
      for (const salaryType of userData.candidate.activeSalaryTypes) {
        const salaryField = `salary${GeneralUtility.capitalizeFirstLetter(
          salaryType
        )}`;

        if (
          !userHasSalaryDefined &&
          userData.candidate[salaryField].length > 0
        ) {
          userHasSalaryDefined = true;
        }

        const matchFound = !!userData.candidate[salaryField]?.find(
          (salaryItem) => jobSalaryConfig.includes(salaryItem)
        );

        if (matchFound) {
          return true;
        }
      }

      if (!userHasSalaryDefined) {
        return true;
      }
    }
    return false;
  },
};

export default salaryConfig;
