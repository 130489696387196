export const CANDIDATE = {
  DEGREE: {
    VOLLJURIST: "fully_qualified_lawyer",
    JURIST: "lawyer",
    WIRSTSCHAFTSJURIST: "commercial_lawyer",
    STUDENT_ONGOING: "student",
    STUDENT_MAIN_FOCUS: "student_with_main_focus",
    STUDENT_PFLICHTTEIL: "student_with_state_part",
    TRAINEE: "trainee_lawyer",
    RENO_REFA_ASSISTENCE: "lawyer_assistance",
  },
};

export const PROFILE_TABS = {
  BASIC: "BASIC",
  ADVANCED: "ADVANCED",
  EXPERT: "EXPERT",
};
