import styles from "./Subheader.module.scss";
import PropTypes from "prop-types";
import Icon from "../../Icon";

/**
 *
 * @param employerName
 * @param className
 * @returns {JSX.Element|null}
 * @constructor
 */
const Subheader = ({ className = "", items = [] }) => {
  return (
    <div className={`${styles.subheader} ${className}`}>
      <div className={styles.headerItem}>
        {items.map((item) => {
          if (!item.label) {
            return null;
          }
          return (
            <span key={item.label}>
              <Icon
                name={item.iconName}
                size={item.iconSize || "small"}
                otherClass={`${styles.icon} ${
                  item.iconClass ? item.iconClass : ""
                }`}
              />
              <span className={styles.label}>{item.label}</span>
            </span>
          );
        })}
      </div>
    </div>
  );
};

Subheader.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      iconName: PropTypes.string,
      iconSize: PropTypes.string,
      iconClass: PropTypes.string,
    })
  ),
};

export default Subheader;
