import Icon from "../../../../../components/Icon";
import styles from "./JobSubheader.module.scss";
import JobLib from "../../../libs/JobFromSolrLib";

/**
 *
 * @param employerName
 * @param locations object {<id>: <cityname>, <id>: <cityname>}
 * @param searchArgs
 * @param searchMeta
 * @param searchBoosting
 * @param separator
 * @param limit
 * @param className
 * @returns {JSX.Element|null}
 * @constructor
 */
const JobSubheader = ({
  employerName,
  locations,
  searchArgs,
  searchMeta,
  searchBoosting,
  separator = ", ",
  limit = 3,
  className = "",
}) => {
  // if (!locations || locations.length === 0) return null;

  const geoDatasFromSearchBoosting =
    searchBoosting && Array.isArray(searchBoosting)
      ? searchBoosting.reduce(
          (acc, value) => [...(acc || []), ...(value.filter.geodatas || [])],
          []
        )
      : [];
  const geoDatasFromLocationSearch = searchMeta?.geo?.map(
    (geoData) => geoData.uid
  );
  // set current cities by facet configuration OR by boosting on initial search without any filter set
  const currentGeoDatas =
    searchArgs?.filter?.geodatas ||
    geoDatasFromLocationSearch ||
    geoDatasFromSearchBoosting;
  let currentCities = currentGeoDatas ? [...currentGeoDatas] : [];
  if (searchMeta && searchMeta.geo?.uid) {
    // Add the current search query to the locations so that when you search for a location, it is also pushed to front.
    currentCities.push(searchMeta.geo?.uid);
  }

  return (
    <div className={`${styles.jobSubheader} ${className}`}>
      <div className={styles.jobHeaderItem}>
        {employerName && (
          <span>
            <Icon
              name="interface-home"
              size="small"
              otherClass={styles.employerNameIcon}
            />
            <span className={styles.employerName}>{employerName}</span>
          </span>
        )}

        {locations && Array.isArray(locations) && locations.length > 0 && (
          <span>
            <Icon
              name="location"
              size="default"
              otherClass={styles.locationIcon}
            />
            <span>
              {JobLib.getLocationsAsString(
                locations,
                limit,
                separator,
                currentCities
              )}
            </span>
          </span>
        )}
      </div>
    </div>
  );
};

export default JobSubheader;
