const degreeConfig = {
  key: "degree",
  label: "Abschluss",
  skip: (jobProps) =>
    !jobProps.jobDegreeIds ||
    (Array.isArray(jobProps.jobDegreeIds) &&
      jobProps.jobDegreeIds.length === 0),
  processMatching: (userData, attributes, jobProps) => {
    const degreeIdsToMatch =
      attributes.degrees[userData.candidate.degree]?.jobCareerlevelMappingIDs;
    if (degreeIdsToMatch && Array.isArray(degreeIdsToMatch)) {
      for (const degreeIdToMatch of degreeIdsToMatch) {
        if (jobProps.jobDegreeIds.includes(parseInt(degreeIdToMatch))) {
          return true;
        }
      }
    }
    return false;
  },
};

export default degreeConfig;
