import Link from "next/link";
import stylesList from "../ItemList.module.scss";
import styles from "./ItemDefault.module.scss";
import PropTypes from "prop-types";
import Subheader from "./Subheader";

const ItemDefault = ({ item }) => {
  return (
    <li key={item.id} className={styles.item}>
      <Link href={item.link} prefetch={false}>
        <a className={styles.item__content}>
          {/* Begin: Job Item info text */}
          <div className={stylesList.item__info}>
            <p
              className={`${stylesList.item__title} ${
                styles[`item__title--layout-${item.titleLayout || "default"}`]
              }`}
            >
              {item.title}
            </p>
            {item.subTitle && (
              <p className={`${stylesList.item__subtitle}`}>{item.subTitle}</p>
            )}

            <Subheader items={item.subHeaderItems} />
          </div>
        </a>
      </Link>
    </li>
  );
};

ItemDefault.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    link: PropTypes.string,
    subHeaderItems: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        iconName: PropTypes.string,
        iconSize: PropTypes.string,
        iconClass: PropTypes.string,
      })
    ),
  }),
};

export default ItemDefault;
