const JobDescriptionItem = ({ header, headerColor, bodytext }) => {
  return (
    <div>
      {header && <h3>{header}</h3>}
      <div dangerouslySetInnerHTML={{ __html: bodytext }} />
    </div>
  );
};

export default JobDescriptionItem;
