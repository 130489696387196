const employmentTypeConfig = {
  key: "employmentTypes",
  label: "Anstellungsart",
  skip: (jobProps) =>
    !jobProps.jobEmploymentTypeIds ||
    (Array.isArray(jobProps.jobEmploymentTypeIds) &&
      jobProps.jobEmploymentTypeIds.length === 0),
  processMatching: (userData, attributes, jobProps) => {
    const employmentIdsToMatch =
      attributes.employmentTypes?.[userData.candidate.employmentType]
        ?.jobArbeitszeitenMappingIDs;

    if (employmentIdsToMatch && Array.isArray(employmentIdsToMatch)) {
      for (const employmentIdToMatch of employmentIdsToMatch) {
        if (
          jobProps.jobEmploymentTypeIds.includes(parseInt(employmentIdToMatch))
        ) {
          return true;
        }
      }
    }
    return false;
  },
};

export default employmentTypeConfig;
