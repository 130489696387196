import { CANDIDATE } from "../../Constants";

const scoreByGradeConfig = {
  key: "scoreGrade",
  label: "Mindestnote Ausbildung",
  skip: (jobProps, userData, attributes) => {
    const degreeIdsCommercialLawyer =
      attributes.degrees[CANDIDATE.DEGREE.WIRSTSCHAFTSJURIST]
        ?.jobCareerlevelMappingIDs || [];
    const degreeIdsAssistence =
      attributes.degrees[CANDIDATE.DEGREE.RENO_REFA_ASSISTENCE]
        ?.jobCareerlevelMappingIDs || [];
    const requiredDegreeIds = [
      ...degreeIdsCommercialLawyer,
      ...degreeIdsAssistence,
    ];

    // get degree id's by mapping configuration
    const userDegreeIdsToMatch =
      attributes.degrees[userData.candidate.degree]?.jobCareerlevelMappingIDs;

    const userHasGradeDegree = requiredDegreeIds.some((requiredDegreeId) =>
      userDegreeIdsToMatch.includes(requiredDegreeId)
    );

    return (
      // user has degree with apprenticeship
      !userHasGradeDegree ||
      // job degree != Wirtschaftsjurist && Reno, Refa...
      !jobProps.jobDegreeIds.find((degreeId) =>
        requiredDegreeIds.includes(parseInt(degreeId))
      ) ||
      // job (employer) has no min grade defined
      !jobProps.jobScoreMinGrade ||
      // user has no score selected
      !userData.candidate.scoreGrade
    );
  },
  processMatching: (userData, attributes, jobProps) => {
    return jobProps.jobScoreMinGrade >= userData.candidate.scoreGrade;
  },
};

export default scoreByGradeConfig;
