import { CANDIDATE } from "../../Constants";

const scoreByPointsConfig = {
  key: "scorePoints",
  label: "Mindestpunktzahl Staatsexamen",
  skip: (jobProps, userData, attributes) => {
    const degreeIdsTrainee =
      attributes.degrees[CANDIDATE.DEGREE.TRAINEE]?.jobCareerlevelMappingIDs || [];
    const degreeIdsLawyer =
      attributes.degrees[CANDIDATE.DEGREE.JURIST]?.jobCareerlevelMappingIDs || [];
    const degreeIdsFullyQualifiedLawyer =
      attributes.degrees[CANDIDATE.DEGREE.VOLLJURIST]?.jobCareerlevelMappingIDs ||
      [];
    const requiredDegreeIds = [
      ...degreeIdsLawyer,
      ...degreeIdsTrainee,
      ...degreeIdsFullyQualifiedLawyer,
    ];
    return (
      // job degree != Jurist or Volljurist, Referendar
      !jobProps.jobDegreeIds.find((degreeId) =>
        requiredDegreeIds.includes(parseInt(degreeId))
      ) ||
      // job (employer) has no min score points defined
      !jobProps.jobScoreMinPoints ||
      // user has no score selected
      (!userData.candidate.score1Class && !userData.candidate.score1)
    );
  },
  processMatching: (userData, attributes, jobProps) => {
    const degreeIdsTrainee =
      attributes.degrees[CANDIDATE.DEGREE.TRAINEE]?.jobCareerlevelMappingIDs || [];
    const degreeIdsLawyer =
      attributes.degrees[CANDIDATE.DEGREE.JURIST]?.jobCareerlevelMappingIDs || [];
    const degreeIdsFullyQualifiedLawyer =
      attributes.degrees[CANDIDATE.DEGREE.VOLLJURIST]?.jobCareerlevelMappingIDs ||
      [];
    const minScorePerStaatsexamen = jobProps.jobScoreMinPoints;

    let userScore1 = parseFloat(userData.candidate.score1);

    if (!userScore1) {
      // get score by current score class of user
      userScore1 = parseFloat(
        userData.candidate.score1Class.replace("gt_", "")
      );
    }

    if (
      jobProps.jobDegreeIds.find((degreeId) =>
        [...degreeIdsLawyer, ...degreeIdsTrainee].includes(degreeId)
      ) &&
      minScorePerStaatsexamen <= userScore1
    ) {
      // lawyer with 1. Staatsexamen
      return true;
    }

    if (
      userScore1 &&
      jobProps.jobDegreeIds.find((degreeId) =>
        degreeIdsFullyQualifiedLawyer.includes(degreeId)
      )
    ) {
      // lawyer with 2. Staatsexamen
      let userScore2 = parseFloat(userData.candidate.score2);

      if (!userScore2) {
        // get score by current score class of user
        userScore2 = parseFloat(
          userData.candidate.score2Class.replace("gt_", "")
        );
      }
      if (userScore2) {
        const scoreSum = userScore1 + userScore2;
        return minScorePerStaatsexamen * 2 <= scoreSum;
      }
    }

    return false;
  },
};

export default scoreByPointsConfig;
