import { calcDistance } from "../../../../jobs/libs/GeoLib";
import getConfig from "next/config";

const workLocationConfig = {
  key: "workLocations",
  label: "Arbeitsort",
  skip: (jobProps, userData, attributes) =>
    (!jobProps.jobCoordinatesLatLon ||
      (Array.isArray(jobProps.jobCoordinatesLatLon) &&
        jobProps.jobCoordinatesLatLon.length <= 0)) &&
    !jobProps.jobIsBundesweit &&
    !jobProps.jobIsRemoteOnly,
  processMatching: (userData, attributes, jobProps) => {
    const { publicRuntimeConfig } = getConfig();
    if (jobProps.jobIsBundesweit) {
      return true;
    }
    if (Array.isArray(userData.candidate?.favoriteWorkLocations)) {
      if (userData.candidate?.favoriteWorkLocations.length === 0) {
        // Benutzer will bundesweite Suche => match
        return true;
      } else if (
        !!userData.candidate?.allowRemoteOnlyJobs &&
        jobProps.jobIsRemoteOnly
      ) {
        // job = "100% remote" && user allowed search for remote jobs = match
        return true;
      } else if (
        !Array.isArray(jobProps.jobCoordinatesLatLon) ||
        jobProps.jobCoordinatesLatLon.length === 0
      ) {
        // No geodata info added to job => bundesweit => match
        return true;
      } else {
        for (const workLocation of userData.candidate?.favoriteWorkLocations) {
          for (const jobLocation of jobProps.jobCoordinatesLatLon) {
            if (jobLocation) {
              const [jobLocationLat, jobLocationLon] = jobLocation;
              const radius = workLocation.radius;
              const distance = calcDistance(
                workLocation.geodata.latitude,
                workLocation.geodata.longitude,
                jobLocationLat,
                jobLocationLon
              );
              if (distance <= radius) {
                return true;
              }
            }
          }
        }
      }
    }
    return false;
  },
};

export default workLocationConfig;
