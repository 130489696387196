const fieldOfLawConfig = {
  key: "fieldOfLaws",
  label: "Rechtsgebiet",
  skip: (jobProps) =>
    !jobProps.jobFieldOfLawIds ||
    (Array.isArray(jobProps.jobFieldOfLawIds) &&
      jobProps.jobFieldOfLawIds.length === 0),
  processMatching: (userData, attributes, jobProps) => {
    return !!userData.candidate.fieldOfLaws.find((fieldOfLaw) => {
      return jobProps.jobFieldOfLawIds?.includes(parseInt(fieldOfLaw.uid));
    });
  },
};

export default fieldOfLawConfig;
