import useSWR from "swr";
import getConfig from "next/config";
import UserApiService from "../services/UserApiService";

const fetcher = () =>
  UserApiService.fetchUserdata().then((data) => {
    return data?.data || {};
  });

const useUserData = () => {
  const { publicRuntimeConfig } = getConfig();
  const {
    data: userData,
    isValidating,
    error,
  } = useSWR("user/data", fetcher, {
    ...publicRuntimeConfig.globalSwrConfig,
    errorRetryCount: 0,
    onErrorRetry: (error) => {
      // Never retry on 480.
      if (error.status === 480) return;
    },
    revalidateOnMount: true,
    revalidateOnFocus: true,
  });

  return {
    userData,
    isValidating,
    error,
  };
};

export default useUserData;
