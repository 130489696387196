import styles from "./ProfilMatching.module.scss";
import PropTypes from "prop-types";
import Tooltip from "../../../../../components/Tooltip";
import getConfig from "next/config";
import { getFeatureNotAllowedTooltip } from "../../../lib/UserProfileFeatureUtility";
import Icon from "../../../../../components/Icon";
import useWindowSize from "../../../../../hooks/useWindowSize";
import { TRIGGER_TYPE } from "baseui/tooltip";

const ProfilMatching = ({
  percent,
  tooltipText,
  circleTextOverride,
  circleTextUnit,
  matchingFeatureUnlocked,
  noMatchingPossible,
  isLoggedInUser = false,
  typeButton = "primary",
}) => {
  const { publicRuntimeConfig } = getConfig();
  const { isMobile } = useWindowSize();
  const stroke = 4;
  const radius = 40;

  let colorbar1 = "#c0c0c0";
  let colorbar2 = "#440DEB";

  if (!matchingFeatureUnlocked) {
    // colorbar1 = colorbar2 = "#d0d0d0";
    //
    // // show placeholder text and move user to login / registration
    // percent = 75;
    // circleTextOverride = (
    //   <div>
    //     <Icon size="default" name="lock" /> /{" "}
    //     <span className={styles.matchedFromCount}>10</span>
    //   </div>
    // );
    tooltipText = getFeatureNotAllowedTooltip(
      isLoggedInUser,
      publicRuntimeConfig.features["matching"].slogan,
      publicRuntimeConfig.features["matching"].loginSlogan,
      publicRuntimeConfig.features["matching"].featureLevel,
      <p>{publicRuntimeConfig.features["matching"].featureDescription}</p>
    );
    return (
      <Tooltip
        icon=""
        text={tooltipText}
        triggerType={isMobile ? TRIGGER_TYPE.click : TRIGGER_TYPE.hover}
      >
        <div
          className={`${styles.lockedMatching} match-score`}
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          <a className={`button button--${typeButton}`}>Match?</a>
          {/*<Icon name="bulb" size="medium" />*/}
          {/*<p className={styles.lockedMatchingText}>*/}
          {/*  Passt die*/}
          {/*  <br /> Stelle zu mir?*/}
          {/*</p>*/}
        </div>
      </Tooltip>
    );
  }

  if (noMatchingPossible) {
    return null;
  }

  // get config from attributes
  const normalizedRadius = radius - stroke;
  const circumference = normalizedRadius * 2 * Math.PI;
  const offset = circumference - (percent / 100) * circumference;

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <Tooltip icon="" text={tooltipText}>
        <div
          className={`${styles.match} match-score ${
            !matchingFeatureUnlocked ? styles.matchLocked : ""
          }`}
          data-testid="match-score"
        >
          <span className={styles.match__progress}>
            <span className={styles.match__percent}>
              {circleTextOverride ? circleTextOverride : `${percent}%`}
              {circleTextUnit && (
                <span className={styles.unit}>{circleTextUnit}</span>
              )}
            </span>
            <svg width={radius * 2} height={radius * 2}>
              <circle
                stroke={colorbar1}
                strokeWidth={stroke / 2}
                fill="transparent"
                r={normalizedRadius}
                cx={radius}
                cy={radius}
              />
              <circle
                stroke={colorbar2}
                strokeWidth={stroke}
                strokeDasharray={`${circumference}, ${circumference}`}
                strokeDashoffset={offset}
                fill="transparent"
                r={normalizedRadius}
                cx={radius}
                cy={radius}
              />
            </svg>
          </span>
          <span className="text-small hide-for-medium text-center">
            Details ansehen
          </span>
          {isLoggedInUser && (
            <span className={styles.match__toptext}>Übereinstimmung</span>
          )}
          {/*{isMobile && (*/}
          {/*  <div className={styles.match__tooltipText}>{tooltipText}</div>*/}
          {/*)}*/}
        </div>
      </Tooltip>
    </div>
  );
};

ProfilMatching.propTypes = {
  percent: PropTypes.number,
  tooltipText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  circleTextOverride: PropTypes.string,
  circleTextUnit: PropTypes.string,
  matchingFeatureUnlocked: PropTypes.bool,
  noMatchingPossible: PropTypes.bool,
  isLoggedInUser: PropTypes.bool,
  isMobile: PropTypes.bool,
  typeButton: PropTypes.string,
};

export default ProfilMatching;
