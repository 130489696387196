import { calcDistance } from "../../../jobs/libs/GeoLib";
import { CANDIDATE } from "../Constants";
import React from "react";
import Tooltip from "../../../../components/Tooltip";
import { hasRole } from "../UserProfileFeatureUtility";
import workLocationConfig from "./configs/WorkLocation";
import organisationalFormConfig from "./configs/OrganisationalForm";
import activityConfig from "./configs/Activity";
import fieldOfLawConfig from "./configs/FieldOfLaw";
import employmentTypeConfig from "./configs/EmploymentType";
import degreeConfig from "./configs/Degree";
import salaryConfig from "./configs/Salary";
import scoreByPointsConfig from "./configs/ScoreByPoints";
import scoreByGradeConfig from "./configs/ScoreByGrade";
import workExperienceConfig from "./configs/WorkExperience";

const matchingConfigs = [
  workLocationConfig,
  organisationalFormConfig,
  activityConfig,
  fieldOfLawConfig,
  employmentTypeConfig,
  degreeConfig,
  salaryConfig,
  scoreByPointsConfig,
  scoreByGradeConfig,
  workExperienceConfig,
];

/**
 * Process matching score by checking matching configs
 *
 * @param userData
 * @param attributes
 * @param jobProps {{jobUid, jobFieldOfLawIds, jobCoordinatesLatLon: *[], jobOrganisationalFormId, jobEmploymentTypeIds, jobDegreeIds, jobActivityIds}}
 * @returns {{matchedConfigs: *[], matchCount: number, matchFromCount: number}}
 */
const processMatching = (userData, attributes, jobProps) => {
  const initialResult = {
    matchCount: 0,
    matchFromCount: 0,
    noneMatchConfigs: [],
    matchedConfigs: [],
  };

  const userDegreeConvertedToDegreeIds =
    attributes.degrees[userData?.candidate?.degree]?.jobCareerlevelMappingIDs ||
    [];

  if (
    !userData?.user ||
    !jobProps.jobDegreeIds.find((degreeId) =>
      userDegreeConvertedToDegreeIds.includes(degreeId)
    )
  ) {
    return {
      ...initialResult,
      skipped: true,
    };
  }

  return matchingConfigs.reduce((lastValue, matchingConfig) => {
    if (
      matchingConfig.skip &&
      matchingConfig.skip(jobProps, userData, attributes)
    ) {
      return lastValue;
    }

    if (typeof matchingConfig.label === "function") {
      matchingConfig.label = matchingConfig.label(jobProps, userData);
    }

    const matchSuccess = matchingConfig.processMatching(
      userData,
      attributes,
      jobProps
    );

    if (!matchSuccess) {
      return {
        ...lastValue,
        matchFromCount: lastValue.matchFromCount + 1,
        noneMatchConfigs: [...lastValue.noneMatchConfigs, matchingConfig],
      };
    } else {
      return {
        ...lastValue,
        matchCount: lastValue.matchCount + 1,
        matchFromCount: lastValue.matchFromCount + 1,
        matchedConfigs: [...lastValue.matchedConfigs, matchingConfig],
      };
    }
  }, initialResult);
};

const explainMatching = (matchResult, id) => (
  <>
    <p className="text-small ce--space-before-none ce--space-after-none">
      Der Score basiert auf folgenden Übereinstimmungen: <br />
    </p>
    <ul>
      {matchResult.matchedConfigs.map((matchedConfig) => (
        <li
          className="text-small"
          key={`matchResult-${id}-${matchedConfig.key}`}
        >
          {matchedConfig.label}
        </li>
      ))}
      {matchResult.noneMatchConfigs.map((noneMatchConfig) => (
        <li
          className="text-small"
          key={`matchResult-${id}-${noneMatchConfig.key}`}
        >
          <span className="color-red text-line-through">{noneMatchConfig.label}</span>
        </li>
      ))}
    </ul>
  </>
);

/**
 * Process matching score by checking matching configs
 *
 * @param jobUid
 * @param userData
 * @param attributes
 * @param jobProps {{jobFieldOfLawIds, jobCoordinatesLatLon: *[], jobOrganisationalFormId, jobEmploymentTypeIds, jobDegreeIds, jobActivityIds}}
 * @returns {{matchPercent, matchBottomText, hideMatchingScore}}
 */
export const getMatching = (jobUid, userData, attributes, jobProps) => {
  let matchPercent = 0;
  let matchTooltipText = "";
  let hideMatchingScore = true;
  let matchCount = 0;
  let matchFromCount = 0;

  const hasRoleMatching = hasRole("matching", userData?.user);

  if (hasRoleMatching) {
    // calc matching score for job
    const matchResult = processMatching(userData, attributes, {
      jobUid: jobUid,
      ...jobProps,
    });

    if (!matchResult.skipped) {
      hideMatchingScore = false;
      matchCount = matchResult.matchCount;
      matchFromCount = matchResult.matchFromCount;
      matchPercent = Math.round(
        (matchResult.matchCount / matchResult.matchFromCount) * 100
      );
      matchTooltipText = explainMatching(matchResult, jobUid);
    }
  }

  return {
    matchPercent,
    matchTooltipText,
    hideMatchingScore,
    matchCount,
    matchFromCount,
  };
};
