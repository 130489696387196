import EmployerLib from "./EmployerLib";

export const getUid = (job) => job?.uid;
export const getTitle = (job) => job?.title;
export const getEmailSubject = (job) => job?.emailsubject;
export const getJobsource = (job) => job?.jobsource;

export const getEmployerLogo = (job, allowMaskedLogo = true) => {
  if (job.companylogo?.uid) return job.companylogo;

  if (allowMaskedLogo) {
    const logoMasked = EmployerLib.getEmployerLogoMasked(job.employer);
    const logoDefault = EmployerLib.getEmployerLogo(job.employer);
    return logoMasked?.uid ? logoMasked : logoDefault;
  }

  return EmployerLib.getEmployerLogo(job.employer);
};

export const getEmployerName = (job) => {
  if (job.employer) {
    return EmployerLib.getEmployerName(job.employer);
  }
  return job.companyname;
};

export const getCities = (job) =>
  Array.isArray(job.locations)
    ? job.locations
        .map((location) => location.city.gemeinde)
        .filter((location, index, locations) => {
          return locations.indexOf(location) === index;
        })
    : [];

export const getCitiesAsString = (job, separator = ", ") =>
  Array.isArray(job.locations) ? getCities(job).join(separator) : "";

export const getOgImage = (job) => job.imageMeta?.uid;

export const getEndtime = (job) => parseInt(job.endtime?.timestamp);

export const getHidden = (job) => job.hidden;

export const getDescriptionSorting = (job) =>
  job.sortingDescriptions ||
  "description_responsibilities,description_qualifications,description_benefits";

export const getMinScorePoints = (job) =>
  parseFloat(job?.employer?.scoreMinClass.replace("gt_", "") || 0);

export const getMinScoreGrade = (job) =>
  parseFloat(job?.employer?.scoreMinGrade || 0);

export const getOrganisationalFormId = (job) =>
  parseInt(
    job?.jobmatchOrganisationalForm?.uid ||
      job?.employer?.jobmatchOrganisationalForm?.uid
  );

export const getSalaryConfig = (job) => job.salaries;

export const getApplyLink = (job) => job.applyLink;
