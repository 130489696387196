const activityConfig = {
  key: "activities",
  label: "Tätigkeit",
  skip: (jobProps) =>
    !jobProps.jobActivityIds ||
    (Array.isArray(jobProps.jobActivityIds) &&
      jobProps.jobActivityIds.length === 0),
  processMatching: (userData, attributes, jobProps) => {
    return !!userData.candidate.organisationalFormsActivities.find(
      (organisationalFormsActivity) => {
        return organisationalFormsActivity.activities.find((activity) =>
          jobProps.jobActivityIds?.includes(parseInt(activity.uid))
        );
      }
    );
  },
};

export default activityConfig;
