import { citiesNameLimiter } from "./GeneralLib";

const getUid = (job) => job?.uid;

/**
 * Generate object with geoID as key and cityname as value
 *
 * @param locationIds
 * @param locationStrings
 * @returns {{}}
 */
const getLocations = (locationIds, locationStrings) => {
  let locations = [];

  if (
    !locationIds ||
    !locationStrings ||
    locationIds.length !== locationStrings.length
  ) {
    return locationStrings && Array.isArray(locationStrings)
      ? locationStrings.map((locationString) => ({
          locationId: 0,
          label: locationString,
        }))
      : [];
  }
  for (const [index, locationId] of locationIds.entries()) {
    locations.push({
      locationId: locationId,
      label: locationStrings[index],
    });
  }
  return locations;
};

/**
 * Generate output of job cities like "Frankfurt am Main, Berlin und 1  weiterer"
 * Move current locations to front
 *
 * @param locations object {id: Cityname, id: Cityname}
 * @param limit
 * @param separator
 * @param currentLocations array ['Wiesbaden', 'Mainz', <geoId>, <geoId2>]
 * @returns {string}
 */
const getLocationsAsString = (
  locations,
  limit,
  separator = ", ",
  currentLocations = []
) => {
  if (!locations || !Array.isArray(locations) || locations.lenght === 0) {
    return "";
  }
  // move current cities to front
  let cities = locations;

  if (currentLocations) {
    // ensure that locations are int values so that .includes works corrects
    currentLocations = currentLocations.map((location) => parseInt(location));

    let currentCities = [];
    let moreCities = [];
    for (let location of locations) {
      if (typeof location === "string") {
        // convert string values to expeted format
        location = { locationId: 0, label: location };
      }
      const geoId = location.locationId;
      const city = location.label || "";
      if (city) {
        if (
          currentLocations.includes(parseInt(geoId)) ||
          currentLocations.includes(city.toString().toLowerCase())
        ) {
          currentCities.push(city);
        } else {
          moreCities.push(city);
        }
      }
    }

    cities = [...currentCities, ...moreCities];
  }

  const [reducedCities, moreJobsLabel] = citiesNameLimiter(cities, limit);

  return reducedCities.join(separator) + moreJobsLabel;
};

const getTeaserImage = (job) => {
  return job.teaser_image_uid_intS;
};

const getEmployerLogo = (job) => {
  return job.companylogo_uid_intS;
};

const getEmployerName = (job) => {
  return job.companyname_stringS;
};

const getEmployerLogoMasked = (job) => {
  return job.companylogo_masked_uid_intS;
};

const getOrganisationalForm = (job) => {
  return job?.jobmatch_organisational_form_intS;
};

const getSalaryConfig = (job) => {
  return job[`salary_stringM`] || [];
};

const getFieldOfLaws = (job) => {
  const items = job?.rechtsgebiete_intM || [];
  return filterPlaceholderValues(items);
};

const getCareerLevels = (job) => {
  const items = job?.cat_careerlevels_intM || [];
  return filterPlaceholderValues(items);
};

const getActivities = (job) => {
  const items = job?.activities_intM || [];
  return filterPlaceholderValues(items);
};

const getEmploymentTypes = (job) => {
  const items = job?.cat_arbeitszeiten_intM || [];
  return filterPlaceholderValues(items);
};

const getScoreMinPoints = (job) => job?.score_min_points_doubleS;
const getScoreMinGrade = (job) => job?.score_min_grade_doubleS;
const getCoordinates = (job) =>
  job?.geo_locationM?.map((jobLocation) => jobLocation.split(","));

const getCompanyLogo = (job) => job?.companylogo_uid_intS;
const getCompanyName = (job) => job?.companyname_stringS;
const getTitle = (job) => job?.title;
const getGeoDatas = (job) => job?.geodatas_intM;

/*
 * HELPER
 */

const filterPlaceholderValues = (values) => {
  return values.filter((value) => value !== 0);
};

export default {
  getUid,
  getLocationsAsString,
  getTeaserImage,
  getEmployerLogo,
  getEmployerLogoMasked,
  getLocations,
  getGeoDatas,
  getEmployerName,
  getSalaryConfig,
  getOrganisationalForm,
  getFieldOfLaws,
  getCareerLevels,
  getActivities,
  getEmploymentTypes,
  getScoreMinPoints,
  getScoreMinGrade,
  getCoordinates,
  getCompanyLogo,
  getCompanyName,
  getTitle,
};
