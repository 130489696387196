const organisationalFormConfig = {
  key: "organisationalForms",
  label: "Organisationsform",
  skip: (jobProps) => !jobProps.jobOrganisationalFormId,
  processMatching: (userData, attributes, jobProps) => {
    return !!userData?.candidate?.organisationalFormsActivities?.find(
      (organisationalFormsActivity) => {
        return (
          parseInt(organisationalFormsActivity.organisationalForm.uid) ===
          parseInt(jobProps.jobOrganisationalFormId)
        );
      }
    );
  },
};

export default organisationalFormConfig;
