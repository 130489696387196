import styles from "./ItemList.module.scss";
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import ItemDefault from "./ItemDefault";

const ItemList = ({ items, layout = "default" }) => {
  const renderListItem = (layout, item, index) => {
    switch (layout) {
      case "default":
        return <ItemDefault key={index} item={item} />;
        break;
    }
  };

  if (!items || items.length === 0) {
    return null;
  }

  return (
    <ul className={`${styles.joblist} ${styles["layout-" + layout]}`}>
      {items.map((item, index) => (
        <Fragment key={`job-list-item-${index}`}>
          {renderListItem(layout, item, index)}
        </Fragment>
      ))}
    </ul>
  );
};

ItemList.propTypes = {
  layout: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
};

export default ItemList;
