import React from "react";
import { Alert, Show, DeleteAlt, Check } from "baseui/icon";
import { StatefulTooltip, TRIGGER_TYPE } from "baseui/tooltip";
import PropTypes from "prop-types";
import useWindowSize from "../../hooks/useWindowSize";
import { useStyletron } from "baseui";

const Tooltip = ({
  text,
  placement = "auto",
  icon,
  iconCssOverride = {},
  className,
  children,
}) => {
  const [css] = useStyletron();
  const windowSize = useWindowSize();

  if (icon && children) {
    iconCssOverride = {
      ...iconCssOverride,
      position: "absolute",
      top: "-1rem",
      right: "-1rem",
    };
  } else if (!icon && icon !== "") {
    icon = "Alert";
  }

  return (
    <StatefulTooltip
      accessibilityType="tooltip"
      content={text}
      placement={placement}
      triggerType={
        windowSize.isMobile ? TRIGGER_TYPE.click : TRIGGER_TYPE.hover
      }
      overrides={{
        Body: {
          style: {
            zIndex: 999,
          },
        },
        Inner: {
          style: () => ({
            maxWidth: "35rem",
            fontSize: "1rem",
            lineHeight: "1.2",
          }),
        },
      }}
    >
      <div className={css({ position: "relative", display: "inline-block" })}>
        {icon == "Alert" && (
          <Alert
            className={className}
            overrides={{
              Svg: {
                style: () => ({
                  margin: "0 0.1rem",
                  transform: "rotate(180deg)!important",
                  ...iconCssOverride,
                }),
              },
            }}
            size={20}
            title=""
          />
        )}
        {icon == "DeleteAlt" && (
          <DeleteAlt
            overrides={{
              Svg: {
                style: () => ({
                  margin: "0 0.5rem",
                  transform: "rotate(180deg)!important",
                }),
              },
            }}
            size={25}
            title=""
          />
        )}
        {icon == "Check" && (
          <Check
            overrides={{
              Svg: {
                style: () => ({
                  margin: "0 0.5rem",
                }),
              },
            }}
            size={25}
            title=""
          />
        )}
        {children}
      </div>
    </StatefulTooltip>
  );
};

Tooltip.defaults = {
  placement: "auto",
};

Tooltip.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  placement: PropTypes.string,
};

export default Tooltip;
