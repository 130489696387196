const BOOKMARK_LIST_URL = `${process.env.BASE_PATH}/api/v1/bookmark/list`;

/**
 * add bookmark
 */
export const fetchBookmarksList = () => {
  return fetch(BOOKMARK_LIST_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

/**
 * add bookmark
 */
export const addBookmark = (idToAdd, type) => {
  return fetch(`${process.env.BASE_PATH}/api/v1/bookmark/add`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ id: idToAdd, type: type }),
  });
};

/**
 * delete bookmarks
 */
export const deleteBookmarks = (idsToDelete) => {
  return fetch(`${process.env.BASE_PATH}/api/v1/bookmark/delete`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ ids: idsToDelete }),
  });
};
