import React, { Fragment } from "react";
import JobListItemSearchList from "../JobListItemSearchList";
import styles from "./JobList.module.scss";
import PagePathUtility from "../../../../../libs/PagePathUtility";
import JobFromSolrLib from "../../../libs/JobFromSolrLib";
import ItemList from "../../../../../components/ItemList";

const JobList = ({
  jobs,
  searchArgs,
  searchMeta,
  searchBoosting,
  layout = "extended",
  showNoJobsMessage = false,
  attributes,
}) => {
  if (!jobs) {
    return null;
  }

  // render both layouts with our new global component
  if (["small", "extended"].includes(layout)) {
    return (
      <ItemList
        items={jobs.map((job) => ({
          id: job.uid,
          title: job.title,
          titleLayout: layout === "extended" ? "big" : "",
          link: PagePathUtility.getPathConfig("jobDetailFromSolr", job),
          subHeaderItems: [
            {
              label: JobFromSolrLib.getEmployerName(job),
              iconName: "interface-home",
              iconSize: "default",
              iconClass: styles.employerNameIcon,
            },
            {
              label: job.truecities_stringM?.join(",") || "",
              iconName: "location",
              iconSize: "default",
              iconClass: styles.locationIcon,
            },
          ],
        }))}
      />
    );
  }

  const renderListItem = (layout, job, index) => {
    switch (layout) {
      case "search":
        return (
          <>
            <JobListItemSearchList
              key={index}
              job={job}
              searchArgs={searchArgs}
              searchMeta={searchMeta}
              searchBoosting={searchBoosting}
              attributes={attributes}
            />
          </>
        );
    }
  };

  return (
    <ul className={`${styles.joblist} ${styles["layout-" + layout]}`}>
      {jobs.map((job, index) => (
        <Fragment key={`job-list-item-${index}`}>
          {renderListItem(layout, job, index)}
        </Fragment>
      ))}
    </ul>
  );
};

export default JobList;
